import React, { useEffect, useState, useRef, useReducer } from "react";
import { fetchRockets } from "../data/rocketFamilies.js";
import DarkBarChart from "../graphComponents/BarChart.js";
import { fetchPads } from "../data/pads.js";
import { fetchZones } from "../data/recoveryZones.js";
import { fetchAdditionalGraphInfo } from "../data/graphData.js";

const AdditionalGraphs = () => {
  const [rockets, setRockets] = useState([]);
  const [massPerYear, setMassPerYear] = useState({});
  const [, forceUpdate] = useReducer((x) => x + 1, 0); // Graph does not update on page resize; force update with this

  const chartRef = useRef(null); // Ref to access the chart container
  const dataLoaded = useRef(false); // Ref to signify when data has been loaded

  /*   useEffect(() => {
    (async () => {
      const [padData, zoneData, rocketData] = await Promise.all([
        fetchPads("", {}),
        fetchZones("", {}),
        fetchRockets(),
      ]);

      setPads(padData);
      setZones(zoneData);
      setRockets(rocketData);
      dataLoaded.current = true;
    })();
  }, []); */

  useEffect(() => {
    /*     if (!dataLoaded.current) {
      return;
    } */
    const fetchData = async () => {
      const [graphData] = await Promise.all([fetchAdditionalGraphInfo()]);

      setMassPerYear(graphData.mass_per_year);
    };
    fetchData();
  }, [dataLoaded]);

  useEffect(() => {
    const handleResize = () => {
      forceUpdate();
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="mx-auto graph-container">
      <div style={{ width: "100%" }}>
        <h2 className="centered-heading">Estimated Mass to Orbit</h2>
        <div className="chart-and-stats" ref={chartRef}>
          <DarkBarChart
            seriesInput={[
              {
                data: Object.values(massPerYear).map(Number),
                area: false,
                stack: "total",
                curve: "linear",
                color: "rgb(232, 144, 254)",
                valueFormatter: (value, context) => `${value.toLocaleString()} tonnes`,
              },
            ]}
            xAxisInput={[{ data: Object.keys(massPerYear).map(Number), type: "linear", scaleType: "band" }]}
            showLegend={false}
            chartWidth={chartRef.current?.offsetWidth || 1000}
            totalLaunches={null}
          />
        </div>
      </div>
    </div>
  );
};

export default AdditionalGraphs;
