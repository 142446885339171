import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./LaunchTable.css";
import { fetchLaunchInfo } from "../data/launches";
import { fetchRockets } from "../data/rockets";
import { getPhotoLocation } from "../utils";
const LaunchDetails = () => {
  const { id } = useParams(); // get launch id from URL
  const [launchInfo, setLaunchInfo] = useState({});
  const [rockets, setRockets] = useState([]);
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [launchData, rocketData] = await Promise.all([fetchLaunchInfo(id), fetchRockets()]);
        setLaunchInfo(launchData);
        setRockets(rocketData);
      } catch (err) {
        setError(err.message); // Set error message if fetching fails
      }
    };
    fetchData();
  }, [id]);

  // check to make sure launch exists
  if (error) {
    return <div style={{ textAlign: "center", color: "white" }}>{error}</div>;
  }

  // have loading indicator
  if (!launchInfo || !Object.keys(launchInfo).length) {
    return <div className="loading-indicator"></div>;
  }

  // createMarkup allows for HTML tags in strings to be rendered; some API endpoints return <br>, which needs to not be rendered as text
  const createMarkup = (html) => {
    return { __html: html };
  };

  const rocketOnLaunch = rockets.find((rocket) => rocket.id === launchInfo.rocket);

  return (
    <div className="max-w-6xl mx-auto page-container-launch-page">
      <div className="centered-image">
        <img src={`${getPhotoLocation()}${launchInfo.image}`} alt="Centered" />
        <div className="caption-bar">
          <h2>
            {launchInfo.name} | {rocketOnLaunch?.name}
          </h2>
        </div>
      </div>
      <table>
        <tbody>
          {Object.entries(launchInfo.create_launch_table || {}).map(([key, values]) => (
            <tr key={key}>
              <th className="has-text-align-left" data-align="left">
                <h6>
                  <strong dangerouslySetInnerHTML={createMarkup(key)}></strong>
                </h6>
              </th>
              <td>
                {Array.isArray(values) ? (
                  values.map((value, index) => (
                    <React.Fragment key={index}>
                      {value}
                      <br />
                    </React.Fragment>
                  ))
                ) : (
                  <span>{values}</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LaunchDetails;
