import React, { useEffect, useState, useRef, useReducer } from "react";
import { useParams, useLocation } from "react-router-dom";
import { fetchBoats } from "../data/boats.js";
import { fetchSpacecraftInfo, fetchSpacecraftFamilies } from "../data/spacecraft.js";
import { fetchStageInfo } from "../data/stages.js";
import { fetchZoneInfo, fetchZones } from "../data/recoveryZones.js";
import { fetchRockets } from "../data/rocketFamilies.js";
import { fetchPads, fetchPadInfo } from "../data/pads.js";
import Card from "../informationCards/InformationCard.js";
import SwitchSeriesType from "../graphComponents/DynamicChart.js";
import CustomLegend from "../multipurpose/CustomLegend";
import dayjs from "dayjs";
import { getPhotoLocation } from "../utils";

// calculateStats takes in a list of turnarounds and yields stats
const calculateStats = (data) => {
  const count = data.length + 1; // One added since these are reuses; obv one fewer reuse than flight
  const fastest = Math.min(...data);
  const average = Math.round((data.reduce((a, b) => a + b, 0) / count) * 100) / 100;
  const stdev = Math.round(Math.sqrt(data.reduce((a, b) => a + Math.pow(b - average, 2), 0) / count) * 100) / 100;

  return {
    Fastest: `${fastest} days`,
    Average: `${average} days`,
    "Standard Deviation": `${stdev} days`,
    Flights: `${count}`,
  };
};

const VehicleDetails = () => {
  const { id } = useParams();
  const url = useLocation().pathname;
  const [vehicle, setVehicle] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [chosenDate, setChosenDate] = useState(null);
  const [prevItemsForDisplay, setPrevItemsForDisplay] = useState([]);
  const [rockets, setRockets] = useState([]);
  const [boats, setBoats] = useState([]);
  const [zones, setZones] = useState([]);
  const [pads, setPads] = useState([]);
  const [families, setFamilies] = useState([]);
  const [objectType, setObjectType] = useState("");
  const [turnarounds, setTurnarounds] = useState([]);
  const [showGraph, setShowGraph] = useState(null);
  const [xAxis, setxAxis] = useState([]);
  const [pastLaunches, setPastLaunches] = useState([]);
  const [futureLaunches, setFutureLaunches] = useState([]);
  const [launchNames, setLaunchNames] = useState([]);
  const [turnaroundStats, setTurnaroundStats] = useState([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0); // Graph does not update on page resize; force update with this
  const [error, setError] = useState(null); // State to handle errors

  const chartRef = useRef(null); // Ref to access the chart container
  const dataLoaded = useRef(false); // Ref to signify when data has been loaded

  // Reusable function to handle error
  async function fetchWithErrorHandling(apiFunction, ...args) {
    try {
      return await apiFunction(...args);
    } catch (error2) {
      setError(error2.message);
      return null;
    }
  }

  useEffect(() => {
    (async () => {
      const [boatData, zoneData, rocketData, padData] = await Promise.all([
        fetchBoats(),
        fetchZones("", {}),
        fetchRockets(),
        fetchPads("", {}),
      ]);

      setBoats(boatData);
      setZones(zoneData);
      setRockets(rocketData);
      setPads(padData);
      dataLoaded.current = true;
    })();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!dataLoaded) {
        return;
      }

      let reuseData = [];
      let launchData = [];
      let prevDisplayItems = [];
      let launchNameData = [];
      let turnaroundData = [];
      let xAxisData = [];
      let key = "";
      let prevLaunches = [];
      let futureLaunches = [];
      let vehicleData = {};

      if (url.includes("spacecraft")) {
        vehicleData = await fetchWithErrorHandling(fetchSpacecraftInfo, id, chosenDate);
        if (vehicleData) {
          const familyData = await fetchWithErrorHandling(fetchSpacecraftFamilies);
          reuseData = vehicleData.spacecraft_on_launches;
          launchData = [reuseData.map((reuse) => reuse.launch)];
          prevDisplayItems = vehicleData.display_spacecraft_on_launches;
          setVehicle(vehicleData["spacecraft"]);
          setObjectType("spacecraftInfo");
          setFamilies(familyData);
          key = "spacecraft";
        }
      } else if (url.includes("stage")) {
        vehicleData = await fetchWithErrorHandling(fetchStageInfo, id, chosenDate);
        if (vehicleData) {
          reuseData = vehicleData.stage_and_recoveries;
          launchData = [reuseData.map((reuse) => reuse.launch)];
          prevDisplayItems = vehicleData.display_stage_and_recoveries;
          setVehicle(vehicleData["stage"]);
          setObjectType("stageInfo");
          key = "stage";
        }
      } else if (url.includes("zone")) {
        vehicleData = await fetchWithErrorHandling(fetchZoneInfo, id, chosenDate);
        if (vehicleData) {
          reuseData = vehicleData.stage_and_recoveries;
          launchData = [reuseData.map((reuse) => reuse.launch)];
          prevDisplayItems = vehicleData.display_stage_and_recoveries;
          setVehicle(vehicleData["landing_zone"]);
          setObjectType("zoneInfo");
          key = "zone";
        }
      } else if (url.includes("pad")) {
        vehicleData = await fetchWithErrorHandling(fetchPadInfo, id, chosenDate);
        if (vehicleData) {
          launchData = vehicleData.launches;
          prevDisplayItems = vehicleData.display_launches;
          setVehicle(vehicleData.pad);
          setObjectType("padInfo");
          key = "pad";
        }
      }

      if (!vehicleData) {
        return;
      }

      setStartDate(new Date(vehicleData["start_date"].slice(0, -1)));
      if (chosenDate === null) {
        setChosenDate(new Date(vehicleData["start_date"].slice(0, -1)));
      }

      const currentDate = new Date();
      if (url.includes("stage") || url.includes("spacecraft") || url.includes("zone")) {
        for (const reuse of reuseData) {
          if (reuse[`${key}_turnaround`]) {
            turnaroundData.push(Math.round((reuse[`${key}_turnaround`] / 86400) * 100) / 100);
            launchNameData.push(reuse.launch.name);
          }
        }
        prevLaunches = reuseData.filter((reuse) => new Date(reuse.launch.time) <= currentDate);
        futureLaunches = reuseData.filter((reuse) => new Date(reuse.launch.time) > currentDate);
      } else {
        for (const launch of launchData) {
          if (launch[`${key}_turnaround`]) {
            turnaroundData.push(Math.round((launch[`${key}_turnaround`] / 86400) * 100) / 100);
            launchNameData.push(launch.name);
          }
        }
        prevLaunches = launchData.filter((launch) => new Date(launch.time) <= currentDate);
        futureLaunches = launchData.filter((launch) => new Date(launch.time) > currentDate);
      }

      for (let i = 0; i < turnaroundData.length; i++) {
        xAxisData.push(i);
      }

      if (showGraph === null) {
        if (turnaroundData.length >= 2) {
          setShowGraph(true);
        } else {
          setShowGraph(false);
        }
      }

      setLaunchNames(launchNameData);
      setPastLaunches(prevLaunches);
      setPrevItemsForDisplay(prevDisplayItems);
      setFutureLaunches(futureLaunches);
      setTurnarounds(turnaroundData);
      setTurnaroundStats(calculateStats(turnaroundData.slice(0, prevLaunches.length)));
      setxAxis(xAxisData);
    };
    fetchData();
  }, [id, url, chosenDate, showGraph]);

  useEffect(() => {
    const handleResize = () => {
      forceUpdate();
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // check to make sure vehicle exists
  if (error) {
    return <div style={{ textAlign: "center", color: "white" }}>{error}</div>;
  }

  // have loading indicator
  if (!vehicle) {
    return <div className="loading-indicator"></div>;
  }

  const handleDateChange = (newDate) => {
    const date = dayjs(newDate);
    if (date.isValid()) {
      setChosenDate(date.toDate());
    }
  };

  // Generate the header text based on the vehicle type
  const generateHeaderText = () => {
    if (url.includes("stage")) {
      return `${vehicle.name}${vehicle.nickname ? ` ${vehicle.nickname}` : ""} | ${
        rockets.find((rocket) => vehicle.rocket === rocket.id)?.name
      } ${vehicle.version}`;
    } else if (url.includes("spacecraft")) {
      return `${vehicle.name}${vehicle.nickname ? ` ${vehicle.nickname}` : ""} | ${
        families.find((family) => vehicle.family === family.id)?.name
      } ${vehicle.version}`;
    } else if (url.includes("pad")) {
      return `${vehicle.name} | ${vehicle.nickname}`;
    } else if (url.includes("zone")) {
      return `${vehicle.name} | ${vehicle.nickname}`;
    }
  };

  return (
    <div>
      <div className="mx-auto graph-container">
        {!showGraph ? (
          <div className="centered-image">
            <img src={`${getPhotoLocation()}${vehicle.image.replace("http://127.0.0.1:8000/", "")}`} alt="Centered" />
            <div className="caption-bar">
              <h2>{generateHeaderText()}</h2>
            </div>
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <h1 className="centered-heading">{generateHeaderText()}</h1>
            <h2 className="subheading">Turnaround Time (Days)</h2>
            <div className="chart-and-stats" ref={chartRef}>
              <SwitchSeriesType
                seriesInput={[
                  {
                    data: turnarounds,
                    label: "Turnaround (days)",
                    area: false,
                    stack: "total",
                    curve: "linear",
                  },
                ]}
                xAxisInput={[{ data: xAxis, type: "linear", scaleType: "band" }]}
                xAxisLabels={launchNames}
                showxAxis={false}
                showAdditionalInfo={true}
                pastLaunchCount={pastLaunches.length}
                displayStats={turnaroundStats}
                legend={
                  <CustomLegend
                    legendItems={[
                      { color: "#E890FE", label: "Previous" },
                      { color: "#00CD9E", label: "Future" },
                    ]}
                  />
                }
                chartWidth={chartRef.current?.offsetWidth || 1000}
                onDateChange={handleDateChange}
                selectedDate={chosenDate}
                startDate={startDate}
              />
            </div>
          </div>
        )}
      </div>
      <div className="max-w-6xl mx-auto page-container-launch-page">
        <div className="launch-container">
          {futureLaunches?.length !== 0 ? (
            <>
              <h1 className="centered-heading">Future Launches</h1>
              <div className="launch-cards-container">
                {futureLaunches.map((launch) => (
                  <Card
                    key={launch.id}
                    object={launch}
                    objectType={objectType}
                    subObjects={{ boats: boats, zones: zones, rockets: rockets, pads: pads }}
                    additionalInfo={{}}
                  />
                ))}
              </div>
            </>
          ) : null}
          <h1 className="centered-heading">Past Launches</h1>
          <div className="launch-cards-container">
            {prevItemsForDisplay.map((launch) => (
              <Card
                key={launch.id}
                object={launch}
                objectType={objectType}
                subObjects={{ boats: boats, zones: zones, rockets: rockets, pads: pads }}
                additionalInfo={{}}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleDetails;
