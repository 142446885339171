import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useParams } from "react-router-dom";
import Navbar from "./navbar/navbar.js";
import LaunchesCalendar from "./launch_calendar/LaunchCalendar.js";
import LaunchList from "./launches/LaunchList.js";
import LaunchDetails from "./launches/LaunchDetails.js";
import VehicleList from "./vehicles/vehicleList.js";
import VehicleDetails from "./vehicles/vehicleInfo.js";
import Home from "./home/Home.js";
import FamilyInformation from "./vehicles/RocketStats.js";
import AdditionalGraphs from "./other/AdditionalGraphs.js";
import "./index.css";
import "./output.css";

const FamilyInfoWrapper = () => {
  const { family } = useParams();
  return <FamilyInformation key={family} />;
};

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/graphs/" element={<AdditionalGraphs />} />
            <Route path="/launch-calendar/" element={<LaunchesCalendar />} />
            <Route path="/launches/" element={<LaunchList />} />
            <Route path="/falcon/boosters/" element={<VehicleList type={"stage"} familyID={1} key="falcon" />} />
            <Route path="/falcon/dragon/" element={<VehicleList type={"spacecraft"} familyID={1} key="dragon" />} />
            <Route path="/starship/boosters/" element={<VehicleList type={"stage"} familyID={2} key="superheavy" />} />
            <Route path="/starship/ships/" element={<VehicleList type={"stage"} familyID={2} key="ships" />} />
            <Route path="/pads/" element={<VehicleList type={"pad"} familyID={6579} key="pads" />} />
            <Route
              path="/recoveryzones/"
              element={<VehicleList type={"recoveryzone"} familyID={665463519} key="zones" />}
            />
            <Route path="/launch/:id/" element={<LaunchDetails />} />
            <Route path="/spacecraft/:id/" element={<VehicleDetails key="spacecraft" />} />
            <Route path="/stage/:id/" element={<VehicleDetails key="stage" />} />
            <Route path="/pad/:id/" element={<VehicleDetails key="pad" />} />
            <Route path="/recoveryzone/:id/" element={<VehicleDetails key="zone" />} />
            <Route path="/:family/stats/" element={<FamilyInfoWrapper />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
